@font-face {
  font-family: "HYGothicL";
  src: url(../../assets/fonts/HYGothicL.woff) format("woff");
}
@font-face {
  font-family: "HYGothicM";
  src: url(../../assets/fonts/HYGothicM.woff) format("woff");
}
@font-face {
  font-family: "HYGothicB";
  src: url(../../assets/fonts/HYGothicB.woff) format("woff");
}
.complete {
  background: #ffffff;
}
.complete .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 780px;
  height: 700px;
  margin: 0 auto;
  padding: 300px 0 0;
}
.complete .info h1 {
  font-family: HYGothicM;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 38px;
}
.complete .info p {
  font-family: HYGothicM;
}
.complete .info .notes .note {
  color: red;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 18px;
}
.complete .info table.account {
  margin-bottom: 60px;
}
.complete .info table.period .label {
  text-align: center;
  line-height: 1.2;
}
.complete .info table td {
  border-top: 1px solid #e0dfdf;
  border-bottom: 1px solid #e0dfdf;
  padding: 22px 34px;
  box-sizing: border-box;
  font-family: HYGothicM;
  font-size: 20px;
  line-height: 1;
}
.complete .info table td.label {
  border-right: 1px solid #e0dfdf;
  font-family: HYGothicM;
  color: #3f3f40;
}
.complete .info table td.label .order-num {
  font-family: HYGothicM;
  font-size: 20px;
}
.complete .info table td.price-info {
  background: #f7f9fb;
}
.complete .info table td.price-info .sub-label {
  font-family: HYGothicM;
}
.complete .info table td.price-info .price {
  font-size: 26px;
}
.complete .info table td div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.complete .info table td span.holder {
  margin-left: 30px;
}
.complete .info table td .how-info span {
  font-family: HYGothicM;
  font-size: 20px;
  color: #595757;
}
.complete .info table td .how-info .sub-price span {
  font-family: HYGothicM;
  font-size: 20px;
}
.complete .info table td .price {
  font-family: HYGothicM;
  color: #f74931;
}
.complete .info table td .price span {
  font-family: HYGothicM;
  font-size: 26px;
}
.complete .info table td .notes {
  display: block;
}
.complete .info table td .notes p {
  font-family: HYGothicM;
  font-size: 18px;
  color: #595757;
}
.complete .info table td .notes p.note {
  color: #ff1600;
  font-size: 20px;
  margin: 44px 0 14px;
}
.complete .info .btn-group {
  margin: 30px 0 30px;
}
.complete .info .btn-group a {
  display: inline-flex;
}
.complete .info .btn-group a span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 46px;
  border-radius: 3px;
  font-family: HYGothicM;
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.complete .info .btn-group a span.history {
  background: #e15b10;
}
.complete .info .btn-group a span.homeBtn {
  background: #00bcd4;
}
.complete .info .btn-group a + a {
  margin-left: 16px;
}

