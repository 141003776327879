@charset "UTF-8";
@font-face {
  font-family: "HY고딕B";
  src: url(/assets/fonts/HYGothicB.eot),
    url(/assets/fonts/HYGothicB.woff2) format("woff2");
}
@font-face {
  font-family: "HY고딕L";
  src: url(/assets/fonts/HYGothicL.eot),
    url(/assets/fonts/HYGothicL.woff2) format("woff2");
}
@font-face {
  font-family: "HY고딕M";
  src: url(/assets/fonts/HYGothicM.eot),
    url(/assets/fonts/HYGothicM.woff2) format("woff2");
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ol,
ul,
li,
dl,
dt,
dd,
th,
td,
form,
input,
textarea,
legend,
label,
pre {
  color: #666;
}

body {
  margin: 0;
}

/*
$solo-color: #8ac14c;
$cad-color: #00bcd7;
$smart-color: #e81f61;
$master-color: #7929b5;
*/
/*
solo  , yellow
webtoon green
cad blue
smart red
font care , purple


*/
[data-tooltip-text-b]:hover {
  position: relative;
  color: #666;
  font-family: HY고딕M, sans-serif;
  font-weight: normal;
  line-height: 1.8;
}

[data-tooltip-text-b]:hover:after {
  font-family: HY고딕M, sans-serif;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  border-radius: 5px;
  color: #666;
  font-size: 13px;
  font-weight: normal;
  content: attr(data-tooltip-text-b);
  margin-bottom: 10px;
  top: 35px;
  left: -180px;
  padding: 7px 12px;
  position: absolute;
  width: auto;
  min-width: 180px;
  max-width: 300px;
  word-wrap: normal;
  text-align: justify;
  text-justify: none;
  z-index: 9999;
}

.hide {
  display: none;
}

.apply {
  width: 100%;
  background-color: #ffffff;
  margin-top: 35px;
  display: flex;
  justify-content: center;
}
.apply:before {
  top: 0px;
  left: 0px;
}
.apply .apply-form {
  width: 100%;
  max-width: 870px;
  margin: 0 auto;
  padding: 69px 0 0;
  height: auto !important;
  overflow: auto !important;
  box-sizing: border-box;
}
.apply .apply-form h1 {
  font-family: HY고딕L, sans-serif;
  font-size: 46px;
  line-height: 1;
  margin-bottom: 33px;
  font-weight: normal;
}
.apply .apply-form h2 {
  font-family: HY고딕B, sans-serif;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 20px;
  font-weight: normal;
}
.apply .apply-form p {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
}
.apply .apply-form .company {
  margin-bottom: 55px;
}
.apply .apply-form .company .form-group {
  display: flex;
  justify-content: space-between;
}
.apply .apply-form .company .form-group .radio-group {
  flex-basis: 30%;
  display: flex;
  height: 46px;
  border-radius: 3px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
}
.apply .apply-form .company .form-group .radio-group label {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
.apply .apply-form .company .form-group .radio-group label.disabled {
  cursor: not-allowed;
}
.apply .apply-form .company .form-group .radio-area {
  flex-basis: 60px;
  background-color: #edebee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .company .form-group .radio-area span {
  border-radius: 50%;
  background-color: #fefefe;
  width: 23px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .company .form-group .radio-area span span {
  border-radius: 50%;
  background-color: #000000;
  width: 13px;
  height: 13px;
  display: none;
}
.apply .apply-form .company .form-group .radio-area span span.trial {
  background-color: #edebee;
}
.apply .apply-form .company .form-group .info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}
.apply .apply-form .company .form-group .question {
  border-radius: 50%;
  background-color: #9f9da0;
  font-family: arial;
  width: 15px;
  height: 15px;
  padding-left: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  margin: 15px 10px 10px 10px;
  font-size: 13px;
  font-weight: bold;
}
.apply .apply-form .company .form-group .info p {
  font-family: HY고딕M, sans-serif;
  font-size: 15px;
  line-height: 1.2;
}
.apply .apply-form .company .form-group .info .price {
  font-family: HY고딕M, sans-serif;
  margin-top: 6px;
}
.apply .apply-form .company input {
  display: none;
}
.apply .apply-form .company input:checked + label .radio-area.basic {
  background-color: #cde736;
}
.apply .apply-form .company input:checked + label .radio-area.smart {
  background-color: #08cdd4;
}
.apply .apply-form .company input:checked + label .radio-area.smartPlus {
  background-color: #fa3e7d;
}
.apply .apply-form .company input:checked + label .radio-area span span {
  display: block;
}
.apply .apply-form .edu {
  margin-bottom: 55px;
}
.apply .apply-form .edu .form-group {
  display: flex;
  /*justify-content: space-between;*/
}
.apply .apply-form .edu .form-group .radio-group {
  flex-basis: 250px;
  display: flex;
  height: 46px;
  border-radius: 3px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
  margin-right: 30px;
}
.apply .apply-form .edu .form-group .radio-group label {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
.apply .apply-form .edu .form-group .radio-group label.disabled {
  cursor: not-allowed;
}
.apply .apply-form .edu .form-group .radio-area {
  flex-basis: 60px;
  background-color: #edebee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .edu .form-group .radio-area span {
  border-radius: 50%;
  background-color: #fefefe;
  width: 23px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .edu .form-group .radio-area span span {
  border-radius: 50%;
  background-color: #000000;
  width: 13px;
  height: 13px;
  display: none;
}
.apply .apply-form .edu .form-group .radio-area span span.trial {
  background-color: #edebee;
}
.apply .apply-form .edu .form-group .info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding-left: 32px;
}
.apply .apply-form .edu .form-group .question {
  border-radius: 50%;
  background-color: #9f9da0;
  font-family: arial;
  width: 15px;
  height: 15px;
  padding-left: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  margin: 15px 10px 10px 10px;
  font-size: 13px;
}
.apply .apply-form .edu .form-group .info p {
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 15px;
  line-height: 1.2;
}
.apply .apply-form .edu .form-group .info .price {
  font-family: HY고딕M, sans-serif, sans-serif;
  margin-top: 6px;
}
.apply .apply-form .edu .form-group .info .price2 {
  font-family: HY고딕M, sans-serif, sans-serif;
  margin-top: 6px;
}
.apply .apply-form .edu input {
  display: none;
}
.apply .apply-form .edu input:checked + label .radio-area.basic {
  background-color: #cde736;
}
.apply .apply-form .edu input:checked + label .radio-area.smart {
  background-color: #08cdd4;
}
.apply .apply-form .edu input:checked + label .radio-area.smartPlus {
  background-color: #fa3e7d;
}
.apply .apply-form .edu input:checked + label .radio-area span span {
  display: block;
}
.apply .apply-form .student {
  margin-bottom: 50px;
}
.apply .apply-form .student .form-group {
  border-radius: 3px;
  background-color: #f7f8fb;
  padding: 23px 37px;
}
.apply .apply-form .student .form-group .form-inline {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.apply .apply-form .student .form-group .input-group {
  display: inline-flex;
  margin-right: 18px;
}
.apply .apply-form .student .form-group .input-group label {
  order: 1;
  font-family: HY고딕M, sans-serif, sans-serif;
  color: #3f3f40;
  /*flex-basis: 135px;*/
  padding-right: 0.5em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  margin-top: 2px;
}
.apply .apply-form .student .form-group .input-group input {
  order: 2;
  width: 100%;
  flex-basis: 100%;
  margin: 0;
  border: 1px solid #dfdedf;
  border-radius: 3px;
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 16px;
  outline: none;
  height: 30px;
  box-sizing: border-box;
  flex: 1 1 auto;
}
.apply .apply-form .student .form-group .input-group .w276 {
  width: 276px;
}
.apply .apply-form .student .form-group .input-group .w176 {
  width: 176px;
}
.apply .apply-form .student .form-group .input-group input:focus {
  border-color: #cde736;
}
.apply .apply-form .user {
  margin-bottom: 55px;
}
.apply .apply-form .user span {
  display: inline-flex;
  border-radius: 3px;
  background-color: #cde736;
  width: 40px;
  height: 40px;
  color: #fefefe;
  justify-content: center;
  align-items: center;
  font-family: HY고딕M, sans-serif;
  font-size: 17px;
}
.apply .apply-form .user ul {
  display: flex;
}
.apply .apply-form .user ul > li {
  display: inline-flex;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdedf;
}
.apply .apply-form .user ul > li.disabled {
  display: none;
}
.apply .apply-form .user ul > li.user-direct {
  width: 70px;
}
.apply .apply-form .user ul > li.user-direct.basic {
  border-color: #fcc728;
}
.apply .apply-form .user ul > li.user-direct.webtoon {
  border-color: #cde736;
}
.apply .apply-form .user ul > li.user-direct.smart {
  border-color: #08cdd4;
}
.apply .apply-form .user ul > li.user-direct.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .user ul > li.user-direct input {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 18px;
}
.apply .apply-form .user ul > li + li {
  margin-left: 16px;
}
.apply .apply-form .user ul > li input {
  display: none;
}
.apply .apply-form .user ul > li input:checked + label.webtoon {
  background-color: #cde736;
  color: #ffffff;
}
.apply .apply-form .user ul > li input:checked + label.basic {
  background-color: #fcc728;
  color: #ffffff;
}
.apply .apply-form .user ul > li input:checked + label.smart {
  background-color: #08cdd4;
  color: #ffffff;
}
.apply .apply-form .user ul > li input:checked + label.smartPlus {
  background-color: #fa3e7d;
  color: #ffffff;
}
.apply .apply-form .user ul > li label {
  cursor: pointer;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-family: HY고딕M, sans-serif;
  font-size: 17px;
}
.apply .apply-form .user p {
  display: inline-flex;
  font-family: HY고딕M, sans-serif;
  font-size: 15px;
  line-height: 1;
  margin-top: 29px;
}
.apply .apply-form .user p > a {
  display: inline-flex;
}
.apply .apply-form .user p > a span {
  width: 32px;
  height: 19px;
  font-size: 8px;
  background-color: #afafaf;
  border-radius: 2px;
  margin-left: 9px;
}
.apply .apply-form .user p span {
  display: inline-flex;
  width: 32px;
  height: 19px;
  font-size: 8px;
  background-color: #afafaf;
  border-radius: 2px;
  margin-left: 9px;
  cursor: pointer;
}
.apply .apply-form .user p img {
  margin-left: 9px;
  cursor: pointer;
  align-self: center;
  margin-top: -2px;
}
.apply .apply-form .payment {
  margin-bottom: 55px;
  color: black;
}
.apply .apply-form .payment .form-group {
  display: flex;
  justify-content: space-between;
}
.apply .apply-form .payment .form-group .radio-group {
  flex-basis: 48%;
  display: flex;
  height: 75px;
  border-radius: 3px;
  border: 1px solid #dfddde;
  box-sizing: border-box;
}
.apply .apply-form .payment .form-group .radio-group label {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
.apply .apply-form .payment .form-group .radio-group label.disabled {
  cursor: not-allowed;
}
.apply .apply-form .payment .form-group .radio-group label.hidden {
  display: none;
}
.apply .apply-form .payment .form-group .questionWidth {
  width: 40px;
  vertical-align: middle;
  margin-top: 20px;
}
.apply .apply-form .payment .form-group .price2 {
  margin-left: 40px;
  font-family: HY고딕M, sans-serif, sans-serif;
  color: #666;
}
.apply .apply-form .payment .form-group .price-sale {
  font-family: HY고딕M, sans-serif, sans-serif;
  position: relative;
  color: #666;
}
.apply .apply-form .payment .form-group .price-sale:before {
  border-bottom: 1px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
}
.apply .apply-form .payment .form-group .radio-area {
  flex-basis: 60px;
  background-color: #edebee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .payment .form-group .radio-area span {
  border-radius: 50%;
  background-color: #fefefe;
  width: 23px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.apply .apply-form .payment .form-group .radio-area span span {
  border-radius: 50%;
  background-color: #000000;
  width: 13px;
  height: 13px;
  display: none;
}
.apply .apply-form .payment .form-group .radio-area span span.trial {
  background-color: #edebee;
}
.apply .apply-form .payment .form-group .info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding-left: 32px;
}
.apply .apply-form .payment .form-group .info p {
  font-family: HY고딕B, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: black;
}
.apply .apply-form .payment .form-group .info .price {
  font-family: HY고딕M, sans-serif;
  margin-top: 6px;
  line-height: 1.5;
  color: #666;
}
.apply .apply-form .payment input {
  display: none;
}
.apply .apply-form .payment input:checked + label .radio-area.webtoon {
  background-color: #cde736;
}
.apply .apply-form .payment input:checked + label .radio-area.basic {
  background-color: #fcc728;
}
.apply .apply-form .payment input:checked + label .radio-area.smart {
  background-color: #08cdd4;
}
.apply .apply-form .payment input:checked + label .radio-area.smartPlus {
  background-color: #fa3e7d;
}
.apply .apply-form .payment input:checked + label .radio-area span span {
  display: block;
}
.apply .apply-form .detail {
  margin-bottom: 74px;
}
.apply .apply-form .detail .boxes {
  display: flex;
}
.apply .apply-form .detail .box {
  flex-basis: 400px;
  background-color: #fff;
  padding: 24px 29px;
}
.apply .apply-form .detail .box + .box {
  margin-left: 2px;
}
.apply .apply-form .detail .box h3 {
  font-family: HY고딕B, sans-serif;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
  color: #182e45;
}
.apply .apply-form .detail .box ul li {
  font-family: HY고딕M, sans-serif;
  font-size: 15px;
  position: relative;
  padding-left: 12px;
  word-break: keep-all;
  line-height: 1.7;
  width: 110%;
}
.apply .apply-form .detail .box ul li:before {
  content: "";
  border-radius: 50%;
  background-color: #4d4b4b;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -4px;
  top: 10px;
}
.apply .apply-form .detail .box ul li span {
  margin-left: -15px;
}
.apply .apply-form .detail .box ul li span.space-white {
  margin-left: 0px;
}
.apply .apply-form .detail .box .price {
  font-family: HY고딕L, sans-serif;
  font-size: 42px;
}
.apply .apply-form .detail .box .price .free {
  margin-left: 50px;
}
.apply .apply-form .detail .box .question {
  /*color: #ed5c04;*/
  cursor: pointer;
  height: 30px;
  margin-left: -5px;
}
.apply .apply-form .detail .box .question:hover + .tooltip {
  display: block;
}
.apply .apply-form .detail .box .question_monthly {
  /*color: #ed5c04;*/
  cursor: pointer;
  height: 30px;
  margin-left: -5px;
}
.apply .apply-form .detail .box .question_monthly:hover + .tooltip_monthly {
  display: block;
}
.apply .apply-form .detail .box .tooltip {
  display: none;
  position: absolute;
  background-color: #f6f7fb;
  padding: 8px 8px;
  width: 99%;
  color: #5b5c60;
  box-shadow: 1px 2px 5px 1px rgba(123, 122, 122, 0.7);
  border-radius: 2px;
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 13px;
  z-index: 1;
  line-height: 2;
  top: 85px;
  left: 95%;
  box-sizing: border-box;
  border-bottom: 3px solid #e81f61;
  word-spacing: -1px;
  word-break: keep-all;
}
.apply .apply-form .detail .box .tooltip .note {
  color: red;
  padding: 20px;
}
.apply .apply-form .detail .box .tooltip:before {
  content: "";
}
.apply .apply-form .detail .box .tooltip_monthly {
  display: none;
  position: absolute;
  background-color: #f6f7fb;
  padding: 8px 8px;
  width: 99%;
  color: #5b5c60;
  box-shadow: 1px 2px 5px 1px rgba(123, 122, 122, 0.7);
  border-radius: 2px;
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 13px;
  z-index: 1;
  line-height: 2;
  box-sizing: border-box;
  border-bottom: 3px solid #e81f61;
  word-spacing: -1px;
  word-break: keep-all;
}
.apply .apply-form .detail .box .tooltip_monthly:before {
  content: "";
}
.apply .apply-form .customer .form-group {
  border-radius: 3px;
  background-color: #f7f8fb;
  padding: 23px 37px;
  margin-bottom: 74px;
}
.apply .apply-form .customer .form-group .card-group {
  padding: 20px 0 35px;
}
.apply .apply-form .customer .form-group .card-group label span {
  width: 16px;
  height: 16px;
}
.apply .apply-form .customer .form-group .card-group label span span {
  width: 10px;
  height: 10px;
}
.apply .apply-form .customer .form-group .bank-group {
  padding-top: 20px;
}
.apply .apply-form .customer .form-group .radio-group {
  display: inline-flex;
}
.apply .apply-form .customer .form-group .radio-group + .radio-group {
  margin-left: 35px;
}
.apply .apply-form .customer .form-group .radio-group input {
  display: none;
}
.apply .apply-form .customer .form-group .radio-group input:checked + label {
  font-family: HY고딕M, sans-serif;
}
.apply
  .apply-form
  .customer
  .form-group
  .radio-group
  input:checked
  + label
  span {
  box-shadow: none;
}
.apply
  .apply-form
  .customer
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.webtoon {
  background: #cde736;
}
.apply
  .apply-form
  .customer
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.basic {
  background: #fcc728;
}
.apply
  .apply-form
  .customer
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.smart {
  background: #08cdd4;
}
.apply
  .apply-form
  .customer
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.smartPlus {
  background: #fa3e7d;
}
.apply .apply-form .customer .form-group .radio-group label {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .customer .form-group .radio-group .radio-area {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}
.apply .apply-form .customer .form-group .radio-group span {
  border-radius: 50%;
  background-color: #fefefe;
  width: 19px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1cecf;
  box-sizing: border-box;
  box-shadow: inset 0 7px 7px 0 rgba(232, 231, 231, 0.75);
}
.apply .apply-form .customer .form-group .radio-group span span {
  border-radius: 50%;
  background-color: #d1cecf;
  width: 11px;
  height: 11px;
}
.apply .apply-form .customer .form-group .form-inline {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.apply .apply-form .customer .form-group .form-inline.card {
  padding-bottom: 20px;
}
.apply .apply-form .customer .form-group .form-inline.bank {
  padding-bottom: 20px;
  border-bottom: 2px solid #ffffff;
}
.apply .apply-form .customer .form-group .form-inline.promotion {
  padding-bottom: 20px;
}
.apply .apply-form .customer .form-group .form-inline .form-label {
  font-family: HY고딕M, sans-serif;
  color: #182e45;
  flex-basis: 135px;
  font-size: 16px;
}
.apply .apply-form .customer .form-group .form-inline .form-control {
  align-items: center;
  display: flex;
  flex-grow: 1;
}
.apply .apply-form .customer .form-group .form-inline .form-control.bank-info {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  /*
            &+span {
              margin-left: 30px;
            }
            */
}
.apply
  .apply-form
  .customer
  .form-group
  .form-inline
  .form-control.bank-info
  span:first-child {
  margin-right: 10px;
}
.apply
  .apply-form
  .customer
  .form-group
  .form-inline
  .form-control.bank-info
  span:last-child {
  margin-left: 40px;
}
.apply
  .apply-form
  .customer
  .form-group
  .form-inline
  .form-control
  input.usage-code {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-basis: 187px;
  height: 32px;
  border-radius: 3px;
  font-family: HY고딕M, sans-serif;
  color: #595757;
  padding-left: 10px;
  font-weight: bold;
  margin-right: 20px;
  font-size: 16px;
}
.apply .apply-form .customer .form-group .receipts {
  align-items: flex-start;
  margin: 20px 0 20px;
}
.apply .apply-form .customer .form-group .receipts .form-control {
  flex-wrap: wrap;
  flex-basis: 0;
}
.apply .apply-form .customer .form-group .receipts .radio-group label {
  font-family: HY고딕M, sans-serif;
}
.apply .apply-form .customer .form-group .receipts .radio-group label span {
  width: 14px;
  height: 14px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .radio-group
  label
  span
  span {
  width: 8px;
  height: 8px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .radio-group
  input:checked
  + label {
  font-family: HY고딕M, sans-serif;
}
.apply .apply-form .customer .form-group .receipts .publish {
  border: 1px solid #dfddde;
  background-color: #ffffff;
  width: 100%;
  max-width: 624px;
  padding: 18px 30px;
  box-sizing: border-box;
  margin: 20px 0 0 20px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  > span {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  flex-basis: 100px;
  color: #595757;
}
.apply .apply-form .customer .form-group .receipts .publish .form-inline.usage {
  align-items: flex-start;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select {
  height: 32px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select.is-focused:not(.is-open)
  > .Select-control {
  border-color: #dfdedf;
  box-shadow: none;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  background: #ffffff;
  width: 160px;
  border-radius: 3px;
  height: 32px;
  font-weight: normal;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-value {
  line-height: 32px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-input {
  height: inherit;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-input
  > input {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding: 0;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-value-label {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-arrow-zone
  img {
  vertical-align: middle;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer {
  border-radius: 3px;
  border: 1px solid #595757;
  top: 0;
  padding: 5px 0;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option {
  background: #e6e6e6;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding: 0 10px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.webtoon {
  background: #cde736;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.basic {
  background: #fcc728;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.smart {
  background: #08cdd4;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.smartPlus {
  background: #fa3e7d;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group {
  position: relative;
  margin-left: 12px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  border-radius: 3px;
  background: #ffffff;
  width: 196px;
  flex-basis: 196px;
  height: 32px;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding-left: 12px;
  margin: 0;
  color: #898989;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus {
  outline: none;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.webtoon {
  border-color: #cde736;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.basic {
  border-color: #fcc728;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.smart {
  border-color: #08cdd4;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.smartPlus {
  border-color: #fa3e7d;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  img {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 1;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  input:checked
  + label
  span
  span {
  background-color: #4d4b4b;
}
.apply .apply-form .customer .form-group .receipts .publish .radio-group {
  margin-bottom: 22px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .radio-group
  + .radio-group {
  padding-left: 15px;
}
.apply .apply-form .customer .form-group .receipts .publish .radio-group span {
  width: 14px;
  height: 14px;
}
.apply
  .apply-form
  .customer
  .form-group
  .receipts
  .publish
  .radio-group
  span
  span {
  width: 8px;
  height: 8px;
}
.apply .apply-form .customer .form-group .license {
  display: flex;
  position: relative;
}
.apply .apply-form .customer .form-group .license p {
  font-size: 20px;
  font-family: HY고딕M, sans-serif;
  color: #595757;
  margin: 0;
}
.apply .apply-form .customer .form-group .license p > span {
  font-family: HY고딕M, sans-serif;
  color: #ed5c04;
  border-bottom: 1px solid #ed5c04;
  margin-left: 5px;
  cursor: pointer;
}
.apply .apply-form .customer .form-group .license .tooltip {
  display: none;
  position: absolute;
  background-color: #fcfcee;
  box-shadow: 1px 2px 4.7px 0.3px rgba(123, 122, 122, 0.7);
  right: -22px;
  bottom: 40px;
  max-width: 892px;
  width: 100%;
  padding: 28px;
  box-sizing: border-box;
}
.apply .apply-form .customer .form-group .license .tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 200px;
  bottom: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #fcfcee #fcfcee;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  box-sizing: border-box;
  box-shadow: -2px 3px 3px 0px rgba(123, 122, 122, 0.7);
}
.apply .apply-form .customer .form-group .license .tooltip.show {
  display: block;
}
.apply .apply-form .customer .form-group .license .tooltip h1 {
  font-family: HY고딕M, sans-serif;
  font-size: 22px;
  line-height: 35px;
  margin-bottom: 10px;
}
.apply .apply-form .customer .form-group .license .tooltip h1 img {
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 14px;
}
.apply .apply-form .customer .form-group .license .tooltip pre {
  background: none;
  font-family: HY고딕M, sans-serif;
  color: #3f3f40;
  font-size: 12px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  border: none;
}
.apply .apply-form .customer .form-group .license input {
  display: none;
}
.apply
  .apply-form
  .customer
  .form-group
  .license
  input:checked
  + label
  > span
  span {
  display: block;
}
.apply .apply-form .customer .form-group .license label {
  font-family: HY고딕M, sans-serif;
  font-size: 20px;
  display: flex;
  width: 16px;
  margin-right: 13px;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .customer .form-group .license label > span {
  border: 1px solid #dfdedf;
  background-color: #ffffff;
  width: 16px;
  height: 17px;
  box-sizing: border-box;
}
.apply .apply-form .customer .form-group .license label > span.webtoon {
  border-color: #cde736;
}
.apply .apply-form .customer .form-group .license label > span.basic {
  border-color: #fcc728;
}
.apply .apply-form .customer .form-group .license label > span.smart {
  border-color: #08cdd4;
}
.apply .apply-form .customer .form-group .license label > span.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .customer .form-group .license label > span span {
  display: none;
  font-size: 15px;
}
.apply .apply-form .customer .form-group .license label > span span.webtoon {
  color: #cde736;
}
.apply .apply-form .customer .form-group .license label > span span.basic {
  color: #fcc728;
}
.apply .apply-form .customer .form-group .license label > span span.smart {
  color: #08cdd4;
}
.apply .apply-form .customer .form-group .license label > span span.smartPlus {
  color: #fa3e7d;
}
.apply .apply-form .customer .form-group .checkbox-group {
  margin-bottom: 20px;
}
.apply .apply-form .customer .form-group .checkbox-group input {
  display: none;
}
.apply
  .apply-form
  .customer
  .form-group
  .checkbox-group
  input:checked
  + label
  > span
  span {
  display: block;
}
.apply .apply-form .customer .form-group .checkbox-group label {
  font-family: HY고딕M, sans-serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .customer .form-group .checkbox-group label > span {
  border: 1px solid #dfdedf;
  background-color: #ffffff;
  width: 16px;
  height: 17px;
  box-sizing: border-box;
  margin-right: 13px;
}
.apply .apply-form .customer .form-group .checkbox-group label > span span {
  display: none;
  font-size: 16px;
}
.apply
  .apply-form
  .customer
  .form-group
  .checkbox-group
  label
  > span
  span.webtoon {
  color: #cde736;
}
.apply
  .apply-form
  .customer
  .form-group
  .checkbox-group
  label
  > span
  span.basic {
  color: #fcc728;
}
.apply
  .apply-form
  .customer
  .form-group
  .checkbox-group
  label
  > span
  span.smart {
  color: #08cdd4;
}
.apply
  .apply-form
  .customer
  .form-group
  .checkbox-group
  label
  > span
  span.smartPlus {
  color: #fa3e7d;
}
.apply .apply-form .customer .form-group .card-receipts .form-label {
  align-self: flex-start;
}
.apply .apply-form .customer .form-group .card-receipts .form-control {
  flex-wrap: wrap;
  flex-basis: 0;
}
.apply .apply-form .customer .form-group .customerInfo {
  width: 100%;
  max-width: 630px;
  margin: 22px 0 0 22px;
  border: 1px solid #dfddde;
  background: #ffffff;
  box-sizing: border-box;
  padding: 22px 27px;
}
.apply .apply-form .customer .form-group .customerInfo .row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.apply .apply-form .customer .form-group .customerInfo .row .col-12 {
  flex-grow: 1;
}
.apply .apply-form .customer .form-group .customerInfo .row .col-6 {
  flex-basis: 48%;
}
.apply .apply-form .customer .form-group .customerInfo .row .col-5 {
  flex-basis: 38%;
  display: flex;
  flex-wrap: wrap;
}
.apply .apply-form .customer .form-group .customerInfo .row .col-7 {
  flex-basis: 58%;
}
.apply .apply-form .customer .form-group .customerInfo .row .col-12 {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.apply .apply-form .customer .form-group .customerInfo .row label {
  width: 100%;
  font-family: HY고딕M, sans-serif;
  font-size: 14px;
}
.apply .apply-form .customer .form-group .customerInfo .row input {
  width: 100%;
  flex-basis: 100%;
  margin: 0;
  border: 1px solid #dfdedf;
  border-radius: 3px;
  font-family: HY고딕M, sans-serif;
  font-size: 14px;
  outline: none;
  height: 36px;
  box-sizing: border-box;
  padding-left: 10px;
}
.apply
  .apply-form
  .customer
  .form-group
  .customerInfo
  .row
  input:focus.webtoon {
  border-color: #cde736;
}
.apply .apply-form .customer .form-group .customerInfo .row input:focus.basic {
  border-color: #fcc728;
}
.apply .apply-form .customer .form-group .customerInfo .row input:focus.smart {
  border-color: #08cdd4;
}
.apply
  .apply-form
  .customer
  .form-group
  .customerInfo
  .row
  input:focus.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .customer .form-group .customerInfo .info-box .top {
  display: flex;
  justify-content: flex-end;
}
.apply .apply-form .customer .form-group .customerInfo .info-box .top p {
  cursor: pointer;
  margin: 0;
}
.apply .apply-form .customer .form-group .customerInfo .info-box .top p:after {
  content: "\25B2";
  margin-left: 5px;
}
.apply
  .apply-form
  .customer
  .form-group
  .customerInfo
  .info-box
  .top
  p.show:after {
  content: "\25BC";
}
.apply .apply-form .customer .form-group .customerInfo .info-box .info {
  margin-top: 15px;
  display: none;
}
.apply .apply-form .customer .form-group .customerInfo .info-box .info.show {
  display: block;
}
.apply .apply-form .customer .form-group .customerInfo .info-box p {
  font-family: HY고딕M, sans-serif;
  font-size: 12px;
  color: #595757;
  margin-top: 5px;
}
.apply .apply-form .customer .form-group hr {
  display: block;
  height: 2px;
  background: #ffffff;
  border: none;
  margin: 0;
}
.apply .apply-form .customer .form-group p {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  margin-top: 20px;
}
.apply .apply-form .how .form-group {
  border-radius: 3px;
  background-color: #f7f8fb;
  padding: 23px 37px;
}
.apply .apply-form .how .form-group .card-group {
  padding: 20px 0 35px;
}
.apply .apply-form .how .form-group .card-group label span {
  width: 16px;
  height: 16px;
}
.apply .apply-form .how .form-group .card-group label span span {
  width: 10px;
  height: 10px;
}
.apply .apply-form .how .form-group .bank-group {
  padding-top: 20px;
}
.apply .apply-form .how .form-group .radio-group {
  display: inline-flex;
}
.apply .apply-form .how .form-group .radio-group + .radio-group {
  margin-left: 35px;
}
.apply .apply-form .how .form-group .radio-group input {
  display: none;
}
.apply .apply-form .how .form-group .radio-group input:checked + label {
  font-family: HY고딕M, sans-serif;
}
.apply .apply-form .how .form-group .radio-group input:checked + label span {
  box-shadow: none;
}
.apply
  .apply-form
  .how
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.webtoon {
  background: #cde736;
}
.apply
  .apply-form
  .how
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.basic {
  background: #fcc728;
}
.apply
  .apply-form
  .how
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.smart {
  background: #08cdd4;
}
.apply
  .apply-form
  .how
  .form-group
  .radio-group
  input:checked
  + label
  span
  span.smartPlus {
  background: #fa3e7d;
}
.apply .apply-form .how .form-group .radio-group label {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .how .form-group .radio-group .radio-area {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}
.apply .apply-form .how .form-group .radio-group span {
  border-radius: 50%;
  background-color: #fefefe;
  width: 19px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1cecf;
  box-sizing: border-box;
  box-shadow: inset 0 7px 7px 0 rgba(232, 231, 231, 0.75);
}
.apply .apply-form .how .form-group .radio-group span span {
  border-radius: 50%;
  background-color: #d1cecf;
  width: 11px;
  height: 11px;
}
.apply .apply-form .how .form-group .form-inline {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.apply .apply-form .how .form-group .form-inline.card {
  padding-bottom: 20px;
}
.apply .apply-form .how .form-group .form-inline.bank {
  padding-bottom: 20px;
  border-bottom: 2px solid #ffffff;
}
.apply .apply-form .how .form-group .form-inline.promotion {
  padding-bottom: 20px;
}
.apply .apply-form .how .form-group .form-inline .form-label {
  font-family: HY고딕M, sans-serif;
  color: #182e45;
  flex-basis: 135px;
  font-size: 16px;
}
.apply .apply-form .how .form-group .form-inline .form-control {
  align-items: center;
  display: flex;
  flex-grow: 1;
}
.apply .apply-form .how .form-group .form-inline .form-control.bank-info {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  /*
            &+span {
              margin-left: 30px;
            }
            */
}
.apply
  .apply-form
  .how
  .form-group
  .form-inline
  .form-control.bank-info
  span:first-child {
  margin-right: 10px;
}
.apply
  .apply-form
  .how
  .form-group
  .form-inline
  .form-control.bank-info
  span:last-child {
  margin-left: 40px;
}
.apply
  .apply-form
  .how
  .form-group
  .form-inline
  .form-control
  input.usage-code {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-basis: 187px;
  height: 32px;
  border-radius: 3px;
  font-family: HY고딕M, sans-serif;
  color: #595757;
  padding-left: 10px;
  font-weight: bold;
  margin-right: 20px;
  font-size: 16px;
}
.apply .apply-form .how .form-group .receipts {
  align-items: flex-start;
  margin: 20px 0 20px;
}
.apply .apply-form .how .form-group .receipts .form-control {
  flex-wrap: wrap;
  flex-basis: 0;
}
.apply .apply-form .how .form-group .receipts .radio-group label {
  font-family: HY고딕M, sans-serif;
}
.apply .apply-form .how .form-group .receipts .radio-group label span {
  width: 14px;
  height: 14px;
}
.apply .apply-form .how .form-group .receipts .radio-group label span span {
  width: 8px;
  height: 8px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .radio-group
  input:checked
  + label {
  font-family: HY고딕M, sans-serif;
}
.apply .apply-form .how .form-group .receipts .publish {
  border: 1px solid #dfddde;
  background-color: #ffffff;
  width: 100%;
  max-width: 624px;
  padding: 18px 30px;
  box-sizing: border-box;
  margin: 20px 0 0 20px;
}
.apply .apply-form .how .form-group .receipts .publish .form-inline > span {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  flex-basis: 100px;
  color: #595757;
}
.apply .apply-form .how .form-group .receipts .publish .form-inline.usage {
  align-items: flex-start;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select {
  height: 32px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select.is-focused:not(.is-open)
  > .Select-control {
  border-color: #dfdedf;
  box-shadow: none;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  background: #ffffff;
  width: 160px;
  border-radius: 3px;
  height: 32px;
  font-weight: normal;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-value {
  line-height: 32px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-input {
  height: inherit;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-input
  > input {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding: 0;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-value-label {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-control
  .Select-arrow-zone
  img {
  vertical-align: middle;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer {
  border-radius: 3px;
  border: 1px solid #595757;
  top: 0;
  padding: 5px 0;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option {
  background: #e6e6e6;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding: 0 10px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.webtoon {
  background: #cde736;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.basic {
  background: #fcc728;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.smart {
  background: #08cdd4;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .usage-select
  .Select-menu-outer
  .option.is-focused.smartPlus {
  background: #fa3e7d;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group {
  position: relative;
  margin-left: 12px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input {
  border: 1px solid #dfdedf;
  box-sizing: border-box;
  border-radius: 3px;
  background: #ffffff;
  width: 196px;
  flex-basis: 196px;
  height: 32px;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  padding-left: 12px;
  margin: 0;
  color: #898989;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus {
  outline: none;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.webtoon {
  border-color: #cde736;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.basic {
  border-color: #fcc728;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.smart {
  border-color: #08cdd4;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  input:focus.smartPlus {
  border-color: #fa3e7d;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .form-inline
  .input-group
  img {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 1;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  input:checked
  + label
  span
  span {
  background-color: #4d4b4b;
}
.apply .apply-form .how .form-group .receipts .publish .radio-group {
  margin-bottom: 22px;
}
.apply
  .apply-form
  .how
  .form-group
  .receipts
  .publish
  .radio-group
  + .radio-group {
  padding-left: 15px;
}
.apply .apply-form .how .form-group .receipts .publish .radio-group span {
  width: 14px;
  height: 14px;
}
.apply .apply-form .how .form-group .receipts .publish .radio-group span span {
  width: 8px;
  height: 8px;
}
.apply .apply-form .how .form-group .license {
  display: flex;
  position: relative;
}
.apply .apply-form .how .form-group .license p {
  font-size: 20px;
  font-family: HY고딕M, sans-serif;
  color: #595757;
  margin: 0;
}
.apply .apply-form .how .form-group .license p > span {
  font-family: HY고딕M, sans-serif;
  color: #ed5c04;
  border-bottom: 1px solid #ed5c04;
  margin-left: 5px;
  cursor: pointer;
}
.apply .apply-form .how .form-group .license .tooltip {
  display: none;
  position: absolute;
  background-color: #fcfcee;
  box-shadow: 1px 2px 4.7px 0.3px rgba(123, 122, 122, 0.7);
  right: -22px;
  bottom: 40px;
  max-width: 892px;
  width: 100%;
  padding: 28px;
  box-sizing: border-box;
}
.apply .apply-form .how .form-group .license .tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 200px;
  bottom: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #fcfcee #fcfcee;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  box-sizing: border-box;
  box-shadow: -2px 3px 3px 0px rgba(123, 122, 122, 0.7);
}
.apply .apply-form .how .form-group .license .tooltip.show {
  display: block;
}
.apply .apply-form .how .form-group .license .tooltip h1 {
  font-family: HY고딕M, sans-serif;
  font-size: 22px;
  line-height: 35px;
  margin-bottom: 10px;
}
.apply .apply-form .how .form-group .license .tooltip h1 img {
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 14px;
}
.apply .apply-form .how .form-group .license .tooltip pre {
  background: none;
  font-family: HY고딕M, sans-serif;
  color: #3f3f40;
  font-size: 12px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  border: none;
}
.apply .apply-form .how .form-group .license input {
  display: none;
}
.apply .apply-form .how .form-group .license input:checked + label > span span {
  display: block;
}
.apply .apply-form .how .form-group .license label {
  font-family: HY고딕M, sans-serif;
  font-size: 20px;
  display: flex;
  width: 16px;
  margin-right: 13px;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .how .form-group .license label > span {
  border: 1px solid #dfdedf;
  background-color: #ffffff;
  width: 16px;
  height: 17px;
  box-sizing: border-box;
}
.apply .apply-form .how .form-group .license label > span.webtoon {
  border-color: #cde736;
}
.apply .apply-form .how .form-group .license label > span.basic {
  border-color: #fcc728;
}
.apply .apply-form .how .form-group .license label > span.smart {
  border-color: #08cdd4;
}
.apply .apply-form .how .form-group .license label > span.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .how .form-group .license label > span span {
  display: none;
  font-size: 15px;
}
.apply .apply-form .how .form-group .license label > span span.webtoon {
  color: #cde736;
}
.apply .apply-form .how .form-group .license label > span span.basic {
  color: #fcc728;
}
.apply .apply-form .how .form-group .license label > span span.smart {
  color: #08cdd4;
}
.apply .apply-form .how .form-group .license label > span span.smartPlus {
  color: #fa3e7d;
}
.apply .apply-form .how .form-group .checkbox-group {
  margin-bottom: 20px;
}
.apply .apply-form .how .form-group .checkbox-group input {
  display: none;
}
.apply
  .apply-form
  .how
  .form-group
  .checkbox-group
  input:checked
  + label
  > span
  span {
  display: block;
}
.apply .apply-form .how .form-group .checkbox-group label {
  font-family: HY고딕M, sans-serif;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.apply .apply-form .how .form-group .checkbox-group label > span {
  border: 1px solid #dfdedf;
  background-color: #ffffff;
  width: 16px;
  height: 17px;
  box-sizing: border-box;
  margin-right: 13px;
}
.apply .apply-form .how .form-group .checkbox-group label > span span {
  display: none;
  font-size: 16px;
}
.apply .apply-form .how .form-group .checkbox-group label > span span.webtoon {
  color: #cde736;
}
.apply .apply-form .how .form-group .checkbox-group label > span span.basic {
  color: #fcc728;
}
.apply .apply-form .how .form-group .checkbox-group label > span span.smart {
  color: #08cdd4;
}
.apply
  .apply-form
  .how
  .form-group
  .checkbox-group
  label
  > span
  span.smartPlus {
  color: #fa3e7d;
}
.apply .apply-form .how .form-group .card-receipts .form-label {
  align-self: flex-start;
}
.apply .apply-form .how .form-group .card-receipts .form-control {
  flex-wrap: wrap;
  flex-basis: 0;
}
.apply .apply-form .how .form-group .bill {
  width: 100%;
  max-width: 630px;
  margin: 22px 0 0 22px;
  border: 1px solid #dfddde;
  background: #ffffff;
  box-sizing: border-box;
  padding: 22px 27px;
}
.apply .apply-form .how .form-group .bill .row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.apply .apply-form .how .form-group .bill .row .col-12 {
  flex-grow: 1;
}
.apply .apply-form .how .form-group .bill .row .col-6 {
  flex-basis: 48%;
}
.apply .apply-form .how .form-group .bill .row .col-5 {
  flex-basis: 38%;
  display: flex;
  flex-wrap: wrap;
}
.apply .apply-form .how .form-group .bill .row .col-7 {
  flex-basis: 58%;
}
.apply .apply-form .how .form-group .bill .row .col-12 {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.apply .apply-form .how .form-group .bill .row label {
  width: 100%;
  font-family: HY고딕M, sans-serif;
  font-size: 14px;
}
.apply .apply-form .how .form-group .bill .row input {
  width: 100%;
  flex-basis: 100%;
  margin: 0;
  border: 1px solid #dfdedf;
  border-radius: 3px;
  font-family: HY고딕M, sans-serif;
  font-size: 14px;
  outline: none;
  height: 36px;
  box-sizing: border-box;
  padding-left: 10px;
}
.apply .apply-form .how .form-group .bill .row input:focus.webtoon {
  border-color: #cde736;
}
.apply .apply-form .how .form-group .bill .row input:focus.basic {
  border-color: #fcc728;
}
.apply .apply-form .how .form-group .bill .row input:focus.smart {
  border-color: #08cdd4;
}
.apply .apply-form .how .form-group .bill .row input:focus.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .how .form-group .bill .info-box .top {
  display: flex;
  justify-content: flex-end;
}
.apply .apply-form .how .form-group .bill .info-box .top p {
  cursor: pointer;
  margin: 0;
}
.apply .apply-form .how .form-group .bill .info-box .top p:after {
  content: "\25B2";
  margin-left: 5px;
}
.apply .apply-form .how .form-group .bill .info-box .top p.show:after {
  content: "\25BC";
}
.apply .apply-form .how .form-group .bill .info-box .info {
  margin-top: 15px;
  display: none;
}
.apply .apply-form .how .form-group .bill .info-box .info.show {
  display: block;
}
.apply .apply-form .how .form-group .bill .info-box p {
  font-family: HY고딕M, sans-serif;
  font-size: 12px;
  color: #595757;
  margin-top: 5px;
}
.apply .apply-form .how .form-group hr {
  display: block;
  height: 2px;
  background: #ffffff;
  border: none;
  margin: 0;
}
.apply .apply-form .how .form-group p {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  margin-top: 20px;
}
.apply .apply-form .master {
  margin: 42px 0 114px;
}
.apply .apply-form .master .form-group {
  display: flex;
  justify-content: space-between;
}
.apply .apply-form .master .form-group + .form-group {
  margin-top: 45px;
}
.apply .apply-form .master .form-group .col-6 {
  flex-basis: 48%;
  flex-grow: inherit;
}
.apply .apply-form .master .form-group > div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.apply .apply-form .master .form-group > div label {
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  flex-grow: 1;
  margin-bottom: 10px;
}
.apply .apply-form .master .form-group > div textarea {
  font-family: HY고딕M, sans-serif;
  border: 1px solid #dfddde;
  border-radius: 3px;
  box-sizing: border-box;
  background: #fefefe;
  height: 160px;
  padding: 10px;
}
.apply .apply-form .master .form-group > div textarea:hover {
  border-color: #737373;
}
.apply .apply-form .master .form-group > div textarea:focus {
  outline: none;
  border-color: #8991fb;
}
.apply .apply-form .master .form-group > div input {
  font-family: HY고딕M, sans-serif;
  padding-left: 10px;
  border: 1px solid #dfddde;
  border-radius: 3px;
  box-sizing: border-box;
  background: #fefefe;
  height: 40px;
}
.apply .apply-form .master .form-group > div input:hover {
  border-color: #737373;
}
.apply .apply-form .master .form-group > div input:focus {
  outline: none;
  border-color: #8991fb;
}
.apply .apply-form .qna {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
}
.apply .apply-form .qna label {
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}
.apply .apply-form .qna textarea {
  border: 1px solid #dfddde;
  box-sizing: border-box;
  border-radius: 3px;
  height: 280px;
  font-family: HY고딕M, sans-serif, sans-serif;
  font-size: 16px;
  padding: 18px 20px;
  outline: none;
}
.apply .apply-form .qna textarea:hover {
  border-color: #737373;
}
.apply .apply-form .qna textarea:focus.webtoon {
  border-color: #cde736;
}
.apply .apply-form .qna textarea:focus.basic {
  border-color: #fcc728;
}
.apply .apply-form .qna textarea:focus.smart {
  border-color: #08cdd4;
}
.apply .apply-form .qna textarea:focus.smartPlus {
  border-color: #fa3e7d;
}
.apply .apply-form .btn-group {
  margin: 74px 0 84px;
}
.apply .apply-form .btn-group button {
  border-radius: 3px;
  background-color: #ed5c04;
  width: 176px;
  height: 46px;
  border: none;
  color: #ffffff;
  font-size: 19px;
  font-family: HY고딕M, sans-serif;
  cursor: pointer;
}
.apply .apply-form .btn-group button.trial {
  background-color: #00bcd4;
}
.apply .apply-form .btn-group button.master {
  background-color: #00bcd4;
  margin: 0;
}
.apply .apply-form .btn-group button.disabled {
  cursor: not-allowed;
}
.apply .apply-form .btn-group button.cancel {
  background: #10b1c7;
}
.apply .apply-form .btn-group button.qna {
  background: #00bcd4;
  display: inline-block;
  flex-direction: initial;
  margin: 0;
}
.apply .apply-form .btn-group button.promotion {
  padding: 0;
}
.apply .apply-form .btn-group button + button {
  margin-left: 18px;
}
.apply .apply-form .btn-group a {
  border-radius: 3px;
  background-color: #10b1c7;
  width: 236px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin-right: 30px;
}
.apply .apply-form .promotion {
  box-sizing: border-box;
}
.apply .apply-form .promotion .input-group {
  margin-left: 22px;
}
.apply .apply-form .promotion .input-group input {
  border: 1px solid #dfddde;
  border-radius: 3px;
  background: #fefefe;
  width: 312px;
  height: 38px;
  outline: none;
  padding-left: 16px;
  box-sizing: border-box;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
}
.apply .apply-form .promotion .input-group input.webtoon:focus {
  border-color: #cde736;
}
.apply .apply-form .promotion .input-group input.basic:focus {
  border-color: #fcc728;
}
.apply .apply-form .promotion .input-group input.smart:focus {
  border-color: #08cdd4;
}
.apply .apply-form .promotion .input-group input.smart-plus:focus {
  border-color: #fa3e7d;
}
.apply .apply-form .promotion .input-group button {
  border: 1px solid #10b1c7;
  color: #10b1c7;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
  background: #fefefe;
  margin-left: 13px;
  border-radius: 3px;
  width: 97px;
  height: 38px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}
.apply .apply-form .promotion .input-group button:hover {
  background: #10b1c7;
  color: white;
}
.apply .apply-form .promotion .message {
  margin: 25px 22px;
  font-family: HY고딕M, sans-serif;
  font-size: 16px;
}
.apply .apply-form .promotion .message.success {
  color: #595757;
}
.apply .apply-form .promotion .message.error {
  color: #f40c1e;
}

.tooltips {
  position: relative;
}

.tooltips:after {
  font-family: HY고딕M, sans-serif;
  position: absolute;
  padding: 8px;
  border: 0px solid #fff;
  line-height: 1.2px;
  letter-spacing: -1px;
  font-size: 12px;
  color: #fff;
  content: attr(data-tooltip-text);
  width: auto;
  min-width: 60px;
  width: -webkit-max-content;
  opacity: 0;
  transition: all 0.1s ease-in-out 0.1s;
  visibility: hidden;
  z-index: 2;
  text-align: left;
}

.tooltips:hover:after {
  opacity: 1;
  visibility: visible;
}

/*Tooltip starting positions*/
.tooltip-bottom:after {
  top: 155%;
  left: -3px;
}

.tooltip-bottom-login:after {
  top: 155%;
  left: 10px;
}

/*Tooltip final positions*/
.tooltip-bottom:hover:after {
  top: 125%;
}

.tooltip-bottom-login:hover:after {
  top: 125%;
}

.subHeader {
  background-color: #2e3643;
  background-size: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  content: "";
  height: 75px;
  /*position: absolute;*/
}
.subHeader .nav {
  min-width: 1024px;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
  /*padding:30px;*/
  display: flex;
  align-items: center;
  height: 100%;
}
.subHeader .nav .nav-logo {
  flex-basis: 15%;
}
.subHeader .nav .nav-menu {
  flex-basis: 85%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
  /*고객센터*/
  /* 카탈로그*/
  /*마이페이지*/
  /*로그아웃*/
  /* 로그인 */
}
.subHeader .nav .nav-menu a:link {
  color: #fff;
}
.subHeader .nav .nav-menu a:visited {
  color: #fff;
}
.subHeader .nav .nav-menu li {
  color: #fff;
  padding: 5px;
}
.subHeader .nav .nav-menu #navlist {
  display: inline-flex;
}
.subHeader .nav .nav-menu #navlist a {
  margin-right: 5px;
}
.subHeader .nav .nav-menu #nav-sub-list {
  display: flex;
}
.subHeader .nav .nav-menu #nav-sub-list li {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
}
.subHeader .nav .nav-menu #nav-sub-list a {
  height: 44px;
  display: flex;
  flex-basis: 44px;
}
.subHeader .nav .nav-menu #nav-sub-menu1 {
  background: url("/assets/img/top-menu.png") no-repeat -16px -8px;
  width: 26px;
  height: 24px;
  text-indent: -9999px;
  display: flex;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.subHeader .nav .nav-menu #nav-sub-menu1:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.subHeader .nav .nav-menu #nav-sub-menu2 {
  width: 25px;
  height: 25px;
  background: url("/assets/img/top-menu.png") no-repeat -59px -6px;
  text-indent: -9999px;
  display: flex;
  opacity: 0.5;
  margin-left: 15px;
  filter: alpha(opacity=50);
}
.subHeader .nav .nav-menu #nav-sub-menu2:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.subHeader .nav .nav-menu #nav-sub-menu5 {
  width: 25px;
  height: 25px;
  background: url("/assets/img/top-menu.png") no-repeat -102px -6px;
  text-indent: -9999px;
  display: flex;
  opacity: 0.5;
  margin-left: 15px;
  filter: alpha(opacity=50);
}
.subHeader .nav .nav-menu #nav-sub-menu5:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.subHeader .nav .nav-menu #nav-sub-menu4 {
  width: 29px;
  height: 28px;
  background: url("/assets/img/top-menu.png") no-repeat -185px -5px;
  text-indent: -9999px;
  display: flex;
  opacity: 0.5;
  margin-left: 15px;
  filter: alpha(opacity=50);
}
.subHeader .nav .nav-menu #nav-sub-menu4:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.subHeader .nav .nav-menu #nav-sub-menu3 {
  width: 29px;
  height: 28px;
  background: url("/assets/img/top-menu.png") no-repeat -185px -5px;
  text-indent: -9999px;
  display: flex;
  opacity: 0.5;
  margin-left: 15px;
  filter: alpha(opacity=50);
}
.subHeader .nav .nav-menu #nav-sub-menu3:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

@-webkit-keyframes license-border {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes license-border {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/*
@media screen and (max-width: 768px) {
  .apply-form .how .form-group .form-inline {
    flex-wrap: wrap; } }

	*/
